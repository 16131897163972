import '../styles/default.scss';
import siteLogo from '../assets/images/fjlo-logo.svg';
import dale_oportunidad_landing from '../assets/images/dale-oportunidad-landing.png';
import sideLanding from '../assets/images/side-landing.svg';
import sideLanding2 from '../assets/images/side-2-landing.svg';
import founderImage from '../assets/images/founder.png';
import { webIcons } from '../constants/images';

function LandingMobile() {
  return (
    <div id="page" className="home">
      <main>
        <a
          className="mainSite"
          href="https://www.jesusluzdeoportunidades.org.pa/"
          target="_self"
        >
          <img
            className="site-logo"
            src={siteLogo}
            alt="Logo de Fundación Jesús Luz de Oportunidades"
            width="100"
          />
        </a>
        <picture className="main-content">
          <img
            src={dale_oportunidad_landing}
            alt="Dale a Panamá la Oportunidad"
            width={100}
          />
        </picture>
        <div className="side-a">
          <img src={sideLanding} alt="sides" />
        </div>
        <div className="side-b">
          <img src={sideLanding2} alt="sides" />
        </div>
      </main>

      <section className="founder">
        <h2>¡Por un Panamá Sin Violencia, Acompáñanos!</h2>
        <div className="founder-picture">
          <img
            src={founderImage}
            alt="Imagen del fundador de Fundación Jesús Luz de Oportunidades"
          />
          <p>Ricardo A. Tribaldos G.</p>
        </div>

        <article className="founder-message">
          <p>
            En noviembre de 2008, durante una noche llena de noticias
            espeluznantes de crímenes, asaltos y violencia, sentí la urgencia de
            hacer algo para cambiar la situación en nuestro país. Incapaz de
            soportar la negatividad en los medios, organicé un almuerzo al día
            siguiente con 15 amigos, entre sacerdotes católicos y pastores
            evangélicos.
          </p>
          <p>
            Al ver que todos llegaron comprometidos a encontrar soluciones,
            sentí la presencia de jesucristo entre nosotros. Inspirados por el
            mensaje de amar a Dios y al prójimo, decidimos trabajar juntos para
            construir un futuro mejor para nuestras familias y generaciones
            venideras.
          </p>
        </article>
      </section>
      <section className="our-vision">
        <div className="header">
          <img src={webIcons.vision.src} alt="" width="100" />
          <h2>Nuestra visión</h2>
        </div>
        <p>
          Fundación reconocida por su trayectoria en la transformación y
          resocialización de personas en conflicto con la ley o en riesgo
          social.
        </p>
      </section>
      <section className="our-mission">
        <div className="header">
          <img src={webIcons.mision.src} alt="" width="100" />
          <h2>Nuestra misión</h2>
        </div>
        <p>
          Implementar nuestro modelo integral de Intervención y Resocialización
          de jóvenes, por medio de la Educación, la Formación Vocacional,
          Espiritual y Social, que le permita reinsertarse a la sociedad a
          través de oportunidades dignas de transformación personal.
        </p>
      </section>
    </div>
  );
}

export default LandingMobile;
