import '../styles/default.scss';
import { siteBranding } from '../constants/images';
import asset_door from '../assets/images/doorAsset.png';
import asset_gallery from '../assets/images/galleryAsset.png';
import asset_checkList from '../assets/images/Check-List.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import asset_checkList_1 from '../assets/images/checkList-01.svg';
import asset_checkList_2 from '../assets/images/checkList-02.svg';
import asset_checkList_3 from '../assets/images/checkList-03.svg';
import asset_checkList_4 from '../assets/images/checkList-04.svg';
import asset_checkList_5 from '../assets/images/checkList-05.svg';
import asset_checkList_6 from '../assets/images/checkList-06.svg';
import asset_checkList_7 from '../assets/images/checkList-07.svg';
import asset_checkList_8 from '../assets/images/checkList-08.svg';
import asset_checkList_9 from '../assets/images/checkList-09.svg';
import Slider from '../utils/Slider';

function Como() {
  const imageData = [
    { url: asset_checkList_1, height: 578 },
    { url: asset_checkList_2, height: 834 },
    { url: asset_checkList_3, height: 752 },
    { url: asset_checkList_4, height: 502 },
    { url: asset_checkList_5, height: 785 },
    { url: asset_checkList_6, height: 1308 },
    { url: asset_checkList_7, height: 799 },
    { url: asset_checkList_8, height: 856 },
    { url: asset_checkList_9, height: 894 },
  ];

  return (
    <div id="page" className="como-lo-hacemos">
      <main>
        <header>
          <h2>¿Cómo lo hacemos?</h2>
          <p>¿Cómo lo</p>
        </header>
        <div className="oportunia">
          <img
            src={siteBranding.oportuniaLogo.src}
            alt={siteBranding.oportuniaLogo.alt}
          />
        </div>
        <section className="mission">
          <p>Ofrecer oportunidades a quienes nunca las tuvieron</p>
          <img src={asset_door} alt="Persona contemplando la oportunidad" />
        </section>
        <section className="content">
          <p>
            El programa OPORTUNIA está diseñado para ofrecer oportunidades
            laborales mediante la búsqueda de oﬁcios o empleos apropiados a los
            beneficiarios que participan en el programa de resocialización de La
            Fundación Jesús Luz de Oportunidades.
          </p>
          <picture>
            <img src={asset_gallery} alt="Imagen de actividades" />
          </picture>
          <p>
            Su empresa puede hacer la diferencia al brindar oportunidades
            laborales a un joven que ha pasado por nuestras 8 etapas del
            proceso, para ser formado en un oficio, está preparado y a la espera
            de una oportunidad y una nueva vida.
          </p>
          <img
            className="slogan"
            src={siteBranding.slogan.src}
            alt={siteBranding.slogan.alt}
          />
        </section>
      </main>
      <section className="slide-container">
        <header>
          <div className="title">
            <img src={asset_checkList} alt="Check List" />
            <span>del programa</span>
          </div>
          <div className="indication">
            <FontAwesomeIcon icon={faChevronRight} />
            <p>deslizar</p>
          </div>
        </header>
        <Slider images={imageData} />
        <footer>
          <div className="bullets">
            <FontAwesomeIcon icon={faCircle} />
            <FontAwesomeIcon icon={faCircle} />
            <FontAwesomeIcon icon={faCircle} />
            <FontAwesomeIcon icon={faCircle} />
            <FontAwesomeIcon icon={faCircle} />
            <FontAwesomeIcon icon={faCircle} />
            <FontAwesomeIcon icon={faCircle} />
            <FontAwesomeIcon icon={faCircle} />
            <FontAwesomeIcon icon={faCircle} />
          </div>
          <div className="indication">
            <FontAwesomeIcon icon={faChevronRight} />
            <p>deslizar</p>
          </div>
        </footer>
      </section>
    </div>
  );
}
export default Como;
