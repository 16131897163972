import HTMLFlipBook from 'react-pageflip';
import magazineImages from '../constants/magazineImages';
import '../styles/magazine.scss';
import bgTableImage from '../assets/images/table.jpg';
import { useRef } from 'react';

function MagazineViewer() {
  const book = useRef();

  return (
    <div
      id="magazine"
      className="magazine"
      style={{
        backgroundImage: `url(${bgTableImage})`,
      }}
    >
      <HTMLFlipBook
        width={626}
        height={810}
        showCover={true}
        ref={book}
        maxShadowOpacity={0.5}
      >
        <div id="magazine-page-1" className="magazine-page">
          <picture>
            <source
              srcSet={magazineImages.page_1.webp.normal}
              media="(min-width:650px)"
              type="image/webp"
            />
            <img
              className="page-1"
              src={magazineImages.page_1.jpg.normal}
              alt="Página 1"
            />
          </picture>
        </div>
        <div id="magazine-page-2" className="magazine-page">
          <picture>
            <source
              srcSet={magazineImages.page_2.webp.normal}
              media="(min-width:650px)"
              type="image/webp"
            />
            <img
              className="page-1"
              src={magazineImages.page_2.jpg.normal}
              alt="Página 1"
            />
          </picture>
        </div>
        <div id="magazine-page-3" className="magazine-page">
          <picture>
            <source
              srcSet={magazineImages.page_3.webp.normal}
              media="(min-width:650px)"
              type="image/webp"
            />
            <img
              className="page-1"
              src={magazineImages.page_3.jpg.normal}
              alt="Página 1"
            />
          </picture>
        </div>
        <div id="magazine-page-4" className="magazine-page">
          <picture>
            <source
              srcSet={magazineImages.page_4.webp.normal}
              media="(min-width:650px)"
              type="image/webp"
            />
            <img
              className="page-1"
              src={magazineImages.page_4.jpg.normal}
              alt="Página 1"
            />
          </picture>
        </div>
        <div id="magazine-page-5" className="magazine-page">
          <picture>
            <source
              srcSet={magazineImages.page_5.svg.normal}
              media="(min-width:650px)"
              type="image/svg"
            />
            <img
              className="page-1"
              src={magazineImages.page_5.svg.normal}
              alt="Página 1"
            />
          </picture>
        </div>
        <div id="magazine-page-6" className="magazine-page">
          <picture>
            <source
              srcSet={magazineImages.page_6.webp.normal}
              media="(min-width:650px)"
              type="image/webp"
            />
            <img
              className="page-1"
              src={magazineImages.page_6.jpg.normal}
              alt="Página 1"
            />
          </picture>
        </div>
        <div id="magazine-page-7" className="magazine-page">
          <picture>
            <source
              srcSet={magazineImages.page_7.webp.normal}
              media="(min-width:650px)"
              type="image/webp"
            />
            <img
              className="page-1"
              src={magazineImages.page_7.jpg.normal}
              alt="Página 1"
            />
          </picture>
        </div>
        <div id="magazine-page-8" className="magazine-page">
          <picture>
            <source
              srcSet={magazineImages.page_8.webp.normal}
              media="(min-width:650px)"
              type="image/webp"
            />
            <img
              className="page-1"
              src={magazineImages.page_8.jpg.normal}
              alt="Página 1"
            />
          </picture>
        </div>
      </HTMLFlipBook>

      <div className="actionBtn">
        <button onClick={() => book.current.pageFlip().flipPrev()}>
          Página Anterior
        </button>
        <button onClick={() => book.current.pageFlip().flipNext()}>
          Siguiente Página
        </button>
      </div>
    </div>
  );
}

export default MagazineViewer;
