import React, { useState } from 'react';
import { siteBranding } from '../constants/images';
import asset_gallery_2 from '../assets/images/gallery-2Asset.jpg';
import asset_charCost from '../assets/images/chart-cost.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function Costo() {
  const [activeRows, setActiveRows] = useState({});

  const toggleActive = (index) => {
    setActiveRows((current) => ({
      ...current,
      [index]: !current[index], // Toggle the active state for the row
    }));
  };

  return (
    <div id="page" className="costo">
      <main>
        <header>
          <h2>
            Costo de 1 <span>benificiario </span>
          </h2>
          <a
            className="mainSite"
            href="https://www.jesusluzdeoportunidades.org.pa/"
            target="_self"
          >
            <img src={siteBranding.logo.src} alt={siteBranding.logo.alt} />
          </a>
        </header>
        <section>
          <picture>
            <img
              src={asset_gallery_2}
              alt="Imagen de uno de nuestros benificiarios antes y después de pasar por nuestro programa"
            />
          </picture>
          <div className="info">
            <picture>
              {' '}
              <img src={asset_charCost} alt="" />
            </picture>
            <p>
              costo total <span>B/. 1,200.00</span>
            </p>
          </div>
        </section>
      </main>

      <section className="costs">
        <header>
          <h3>Reinserción social</h3>
          <p>6 meses de atención</p>
        </header>
        <div className="cost-grid">
          <table>
            <thead>
              <tr>
                <th>Acciones</th>
                <th colSpan="5">Descripción</th>
              </tr>
            </thead>
            <tbody>
              <tr
                key={1}
                className={`open-accordion ${activeRows[1] ? 'active' : ''}`}
                onClick={() => toggleActive(1)}
              >
                <td>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <p>1</p>
                </td>
                <td colSpan="3">
                  <p>Inicial</p>
                  <div className="detail">
                    <p>B/. 18.00</p>
                    <p className="subtext">Fondo ud</p>
                  </div>
                </td>
                <td colSpan="2">
                  <p>B/. 18.00</p>
                  <div className="detail">
                    <p className="subtext">Fondo total</p>
                  </div>
                </td>
              </tr>
              <tr
                key={2}
                className={`open-accordion ${activeRows[2] ? 'active' : ''}`}
                onClick={() => toggleActive(2)}
              >
                <td>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <p>6</p>
                </td>
                <td colSpan="3">
                  <p>Talleres</p>
                  <div className="detail">
                    <p>B/. 18.00</p>
                    <p className="subtext">Fondo ud</p>
                  </div>
                </td>
                <td colSpan="2">
                  <p>B/. 108.00</p>
                  <div className="detail">
                    <p className="subtext">Fondo total</p>
                  </div>
                </td>
              </tr>
              <tr
                key={3}
                className={`open-accordion ${activeRows[3] ? 'active' : ''}`}
                onClick={() => toggleActive(3)}
              >
                <td>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <p>6</p>
                </td>
                <td colSpan="3">
                  <p>Talleres Reforzamiento y Auto-ayuda</p>
                  <div className="detail">
                    <p>B/. 18.00</p>
                    <p className="subtext">Fondo ud</p>
                  </div>
                </td>
                <td colSpan="2">
                  <p>B/. 108.00</p>
                  <div className="detail">
                    <p className="subtext">Fondo total</p>
                  </div>
                </td>
              </tr>
              <tr
                key={4}
                className={`open-accordion ${activeRows[4] ? 'active' : ''}`}
                onClick={() => toggleActive(4)}
              >
                <td>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <p>5</p>
                </td>
                <td colSpan="3">
                  <p>Atención Individual</p>
                  <div className="detail">
                    <p>B/. 18.00</p>
                    <p className="subtext">Fondo ud</p>
                  </div>
                </td>
                <td colSpan="2">
                  <p>B/. 90.00</p>
                  <div className="detail">
                    <p className="subtext">Fondo total</p>
                  </div>
                </td>
              </tr>
              <tr
                key={5}
                className={`open-accordion ${activeRows[5] ? 'active' : ''}`}
                onClick={() => toggleActive(5)}
              >
                <td>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <p>5</p>
                </td>
                <td colSpan="3">
                  <p>Entrevista</p>
                  <div className="detail">
                    <p>B/. 18.00</p>
                    <p className="subtext">Fondo ud</p>
                  </div>
                </td>
                <td colSpan="2">
                  <p>B/. 90.00</p>
                  <div className="detail">
                    <p className="subtext">Fondo total</p>
                  </div>
                </td>
              </tr>
              <tr
                key={6}
                className={`open-accordion ${activeRows[6] ? 'active' : ''}`}
                onClick={() => toggleActive(6)}
              >
                <td>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <p>1</p>
                </td>
                <td colSpan="3">
                  <p>Apoyo Social</p>
                  <div className="detail">
                    <p>B/. 50.00</p>
                    <p className="subtext">Fondo ud</p>
                  </div>
                </td>
                <td colSpan="2">
                  <p>B/. 50.00</p>
                  <div className="detail">
                    <p className="subtext">Fondo total</p>
                  </div>
                </td>
              </tr>
              <tr
                key={7}
                className={`open-accordion ${activeRows[7] ? 'active' : ''}`}
                onClick={() => toggleActive(7)}
              >
                <td>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <p>1</p>
                </td>
                <td colSpan="3">
                  <p>Apoyo Salud</p>
                  <div className="detail">
                    <p>B/. 60.00</p>
                    <p className="subtext">Fondo ud</p>
                  </div>
                </td>
                <td colSpan="2">
                  <p>B/. 60.00</p>
                  <div className="detail">
                    <p className="subtext">Fondo total</p>
                  </div>
                </td>
              </tr>
              <tr
                key={8}
                className={`open-accordion ${activeRows[8] ? 'active' : ''}`}
                onClick={() => toggleActive(8)}
              >
                <td>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <p>10</p>
                </td>
                <td colSpan="3">
                  <p>Prueba de Doping</p>
                  <div className="detail">
                    <p>B/. 20.00</p>
                    <p className="subtext">Fondo ud</p>
                  </div>
                </td>
                <td colSpan="2">
                  <p>B/. 200.00</p>
                  <div className="detail">
                    <p className="subtext">Fondo total</p>
                  </div>
                </td>
              </tr>
              <tr
                key={9}
                className={`open-accordion ${activeRows[9] ? 'active' : ''}`}
                onClick={() => toggleActive(9)}
              >
                <td>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <p>6</p>
                </td>
                <td colSpan="3">
                  <p>Pruebas Psicológicas</p>
                  <div className="detail">
                    <p>B/. 25.00</p>
                    <p className="subtext">Fondo ud</p>
                  </div>
                </td>
                <td colSpan="2">
                  <p>B/. 150.00</p>
                  <div className="detail">
                    <p className="subtext">Fondo total</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <h3>Inserción laboral / Autogestión</h3>
          <table className="insert-autogestion">
            <thead>
              <tr>
                <th>Acciones</th>
                <th colSpan="5">Descripción</th>
              </tr>
            </thead>
            <tbody>
              <tr
                key={10}
                className={`open-accordion ${activeRows[10] ? 'active' : ''}`}
                onClick={() => toggleActive(10)}
              >
                <td>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <p>1</p>
                </td>
                <td colSpan="3">
                  <p>Transporte</p>
                  <div className="detail">
                    <p>B/. 50.00</p>
                    <p className="subtext">Fondo ud</p>
                  </div>
                </td>
                <td colSpan="2">
                  <p>B/. 50.00</p>
                  <div className="detail">
                    <p className="subtext">Fondo total</p>
                  </div>
                </td>
              </tr>
              <tr
                key={11}
                className={`open-accordion ${activeRows[11] ? 'active' : ''}`}
                onClick={() => toggleActive(11)}
              >
                <td>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <p>1</p>
                </td>
                <td colSpan="3">
                  <p>Indumentaria</p>
                  <div className="detail">
                    <p>B/. 150.00</p>
                    <p className="subtext">Fondo ud</p>
                  </div>
                </td>
                <td colSpan="2">
                  <p>B/. 150.00</p>
                  <div className="detail">
                    <p className="subtext">Fondo total</p>
                  </div>
                </td>
              </tr>
              <tr
                key={12}
                className={`open-accordion ${activeRows[12] ? 'active' : ''}`}
                onClick={() => toggleActive(12)}
              >
                <td>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <p>7</p>
                </td>
                <td colSpan="3">
                  <p>Seguimiento</p>
                  <div className="detail">
                    <p>B/. 18.00</p>
                    <p className="subtext">Fondo ud</p>
                  </div>
                </td>
                <td colSpan="2">
                  <p>B/. 126.00</p>
                  <div className="detail">
                    <p className="subtext">Fondo total</p>
                  </div>
                </td>
              </tr>
              <tr
                key={13}
                className={`open-accordion ${activeRows[13] ? 'active' : ''}`}
                onClick={() => toggleActive(13)}
              >
                <td>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <p>1</p>
                </td>
                <td colSpan="3">
                  <p>Apoyo de alimentación</p>
                  <div className="detail">
                    <p>B/. 60.00</p>
                    <p className="subtext">Fondo ud</p>
                  </div>
                </td>
                <td colSpan="2">
                  <p>B/. 60.00</p>
                  <div className="detail">
                    <p className="subtext">Fondo total</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="total">
            <div className="row">
              <p>50 Acciones</p>
            </div>
            <div className="row">
              <p>Total: B/. 1,200.00</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Costo;
