import webIcons_vision from '../assets/images/web-icons_vision.svg';
import webIcons_mision from '../assets/images/web-icons_mision.svg';

import webIcons_como from '../assets/images/web-icons_como.svg';
import webIcons_costos from '../assets/images/web-icons_costos.svg';
import webIcons_donar from '../assets/images/web-icons_donar.svg';
import webIcons_home from '../assets/images/web-icons_home.svg';
import webIcons_queOfrecemos from '../assets/images/web-icons_queOfrecemos.svg';

import siteLogo from '../assets/images/fjlo-logo.svg';
import sloganColor from '../assets/images/fjlo-slogan-color.svg';
import slogan from '../assets/images/fjlo-slogan.svg';
import oportunia_logo from '../assets/images/oportunia-logo.svg';

export const webIcons = {
  vision: {
    src: webIcons_vision,
  },
  mision: {
    src: webIcons_mision,
  },
  como: {
    src: webIcons_como,
    alt: 'Icono de "¿Cómo lo hacemos?"',
  },
  costos: {
    src: webIcons_costos,
    alt: 'Icono de "Costos"',
  },
  donar: {
    src: webIcons_donar,
    alt: 'Icono de "Pasos para Donar"',
  },
  home: {
    src: webIcons_home,
    alt: 'Icono de Casa',
  },
  queOfrecemos: {
    src: webIcons_queOfrecemos,
    alt: 'Icono de "¿qué ofrecemos?"',
  },
};

export const siteBranding = {
  logo: {
    src: siteLogo,
    alt: 'Logo de Fundación Jesús Luz de oportunidades',
  },
  slogan: {
    src: slogan,
    alt: 'Slogan de la Fundación "¡Por un Panamá sin violencia... Acompañanos!"',
  },
  sloganColor: {
    src: sloganColor,
    alt: 'Slogan de la Fundación "¡Por un Panamá sin violencia... Acompañanos!"',
  },
  oportuniaLogo: {
    src: oportunia_logo,
    alt: 'Logo del Programa "Oportunia"',
  },
};
