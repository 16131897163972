import magazine_page_1_jpg from '../assets/pages/1.jpg';
import magazine_page_1_jpg_large from '../assets/pages/1-large.jpg';
import magazine_page_2_jpg from '../assets/pages/2.jpg';
import magazine_page_2_jpg_large from '../assets/pages/2-large.jpg';
import magazine_page_3_jpg from '../assets/pages/3.jpg';
import magazine_page_3_jpg_large from '../assets/pages/3-large.jpg';
import magazine_page_4_jpg from '../assets/pages/4.jpg';
import magazine_page_4_jpg_large from '../assets/pages/4-large.jpg';
import magazine_page_5_jpg from '../assets/pages/5.jpg';
import magazine_page_5_jpg_large from '../assets/pages/5-large.jpg';
import magazine_page_6_jpg from '../assets/pages/6.jpg';
import magazine_page_6_jpg_large from '../assets/pages/6-large.jpg';
import magazine_page_7_jpg from '../assets/pages/7.jpg';
import magazine_page_7_jpg_large from '../assets/pages/7-large.jpg';
import magazine_page_8_jpg from '../assets/pages/8.jpg';
import magazine_page_8_jpg_large from '../assets/pages/8-large.jpg';

import magazine_page_1_webp from '../assets/pages/1.webp';
import magazine_page_1_webp_large from '../assets/pages/1-large.webp';
import magazine_page_2_webp from '../assets/pages/2.webp';
import magazine_page_2_webp_large from '../assets/pages/2-large.webp';
import magazine_page_3_webp from '../assets/pages/3.webp';
import magazine_page_3_webp_large from '../assets/pages/3-large.webp';
import magazine_page_4_webp from '../assets/pages/4.webp';
import magazine_page_4_webp_large from '../assets/pages/4-large.webp';
import magazine_page_5_svg from '../assets/pages/5.svg';
import magazine_page_5_webp from '../assets/pages/5.webp';
import magazine_page_5_webp_large from '../assets/pages/5-large.webp';
import magazine_page_6_webp from '../assets/pages/6.webp';
import magazine_page_6_webp_large from '../assets/pages/6-large.webp';
import magazine_page_7_webp from '../assets/pages/7.webp';
import magazine_page_7_webp_large from '../assets/pages/7-large.webp';
import magazine_page_8_webp from '../assets/pages/8.webp';
import magazine_page_8_webp_large from '../assets/pages/8-large.webp';

const magazineImages = {
  page_1: {
    jpg: {
      normal: magazine_page_1_jpg,
      large: magazine_page_1_jpg_large,
    },
    webp: {
      normal: magazine_page_1_webp,
      large: magazine_page_1_webp_large,
    },
  },
  page_2: {
    jpg: {
      normal: magazine_page_2_jpg,
      large: magazine_page_2_jpg_large,
    },
    webp: {
      normal: magazine_page_2_webp,
      large: magazine_page_2_webp_large,
    },
  },
  page_3: {
    jpg: {
      normal: magazine_page_3_jpg,
      large: magazine_page_3_jpg_large,
    },
    webp: {
      normal: magazine_page_3_webp,
      large: magazine_page_3_webp_large,
    },
  },
  page_4: {
    jpg: {
      normal: magazine_page_4_jpg,
      large: magazine_page_4_jpg_large,
    },
    webp: {
      normal: magazine_page_4_webp,
      large: magazine_page_4_webp_large,
    },
  },
  page_5: {
    jpg: {
      normal: magazine_page_5_jpg,
      large: magazine_page_5_jpg_large,
    },
    webp: {
      normal: magazine_page_5_webp,
      large: magazine_page_5_webp_large,
    },
    svg: {
      normal: magazine_page_5_svg,
    },
  },
  page_6: {
    jpg: {
      normal: magazine_page_6_jpg,
      large: magazine_page_6_jpg_large,
    },
    webp: {
      normal: magazine_page_6_webp,
      large: magazine_page_6_webp_large,
    },
  },
  page_7: {
    jpg: {
      normal: magazine_page_7_jpg,
      large: magazine_page_7_jpg_large,
    },
    webp: {
      normal: magazine_page_7_webp,
      large: magazine_page_7_webp_large,
    },
  },
  page_8: {
    jpg: {
      normal: magazine_page_8_jpg,
      large: magazine_page_8_jpg_large,
    },
    webp: {
      normal: magazine_page_8_webp,
      large: magazine_page_8_webp_large,
    },
  },
};

export default magazineImages;
