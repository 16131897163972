import React, { useState, useRef } from 'react';

const Slider = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const startXRef = useRef();

  const handleTouchStart = (evt) => {
    startXRef.current = evt.touches[0].clientX;
  };

  const handleTouchMove = (evt) => {
    const startX = startXRef.current;
    if (!startX) return;

    const touchCurrentX = evt.touches[0].clientX;
    const walk = startX - touchCurrentX;

    if (Math.abs(walk) > 50) {
      // Swipe threshold
      if (walk > 0) nextSlide();
      else previousSlide();
      startXRef.current = null; // Reset startX after handling the swipe
    }
  };

  const nextSlide = () => {
    setCurrentSlide((current) =>
      current < images.length - 1 ? current + 1 : current
    );
  };

  const previousSlide = () => {
    setCurrentSlide((current) => (current > 0 ? current - 1 : current));
  };

  return (
    <div
      className="slider"
      id="slider"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      style={{ height: `${images[currentSlide].height * 1.05}px` }}
    >
      {images.map((img, index) => (
        <div
          key={index}
          className={`slide ${index === currentSlide ? 'active' : ''}`}
          style={{
            backgroundImage: `url(${img.url})`,
            transform:
              index === currentSlide
                ? 'translateX(0)'
                : index < currentSlide
                ? 'translateX(-100%)'
                : 'translateX(100%)',
          }}
          data-height={img.height}
        ></div>
      ))}
    </div>
  );
};

export default Slider;
