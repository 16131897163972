import '../styles/default.scss';
import bgImage from '../assets/images/Home-Fondo-2.jpeg';

function QueOfrecemos() {
  const mainTagStyles = {
    backgroundImage: `url(${bgImage})`,
  };

  return (
    <div id="page" className="que-ofrecemos">
      <main style={mainTagStyles}>
        <h2>¿Qué ofrecemos?</h2>
        <p>¿Qué ofrecemos?</p>
      </main>
      <section className="propuesta-valor">
        <article>
          <header>
            <div>
              <p>1</p>
            </div>
            <h3>Educación / formación</h3>
          </header>
          <section>
            <p>
              Ofrecer alternativas formativas a través de talleres,
              capacitaciones para desarrollar y potenciar habilidades blandas,
              preparación técnica y de estudios a los beneficiarios de la FJLO.
            </p>
          </section>
        </article>
        <article>
          <header>
            <div>
              <p>2</p>
            </div>
            <h3>Espiritualidad</h3>
          </header>
          <section>
            <p>
              Integrar espacios de reﬂexión espiritual a los beneﬁciarios de la
              FJLO, promoviendo la reafirmación de los valores morales y el
              desarrollo de la autonomía personal, apoyando y promoviendo el
              fortalecimiento de la dimensión espiritual.
            </p>
          </section>
        </article>
        <article>
          <header>
            <div>
              <p>3</p>
            </div>
            <h3>Social y Familiar</h3>
          </header>
          <section>
            <p>
              Desarrollar acciones sociales y familiares mediante la
              identificación de necesidades socio-económicas y brindar apoyos
              sociales de forma integral para el fortalecimiento del
              beneficiarios con su entorno familiar y social.
            </p>
          </section>
        </article>
        <article>
          <header>
            <div>
              <p>4</p>
            </div>
            <h3>Salud física y mental</h3>
          </header>
          <section>
            <p>
              Evaluar, orientar y facilitar las rutas de atención salud física
              en base a sus problemáticas; además, la alternativa de recibir
              atenciones multidisciplinarias que fortalezcan positivamente sus
              áreas de mejora en pensamientos, comportamientos y emociones,
              relacionadas con su reinserción social y laboral.
            </p>
          </section>
        </article>
        <article>
          <header>
            <div>
              <p>5</p>
            </div>
            <h3>Legal</h3>
          </header>
          <section>
            <p>
              Brindar asesorías y seguimientos dentro del marco legal y las
              normativas vigentes todas las vinculaciones jurídicas que conlleva
              el estatus legal del beneficiario y familiares en conflicto con la
              ley.
            </p>
          </section>
        </article>
        <article>
          <header>
            <div>
              <p>6</p>
            </div>
            <h3>Laboral</h3>
          </header>
          <section>
            <p>
              Ofrecer alternativas laborales formales e informales para mejorar
              la calidad de vida de los beneﬁciarios y sus familias.
            </p>
          </section>
        </article>
      </section>
    </div>
  );
}

export default QueOfrecemos;
