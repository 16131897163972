import * as React from 'react';
import { webIcons } from '../constants/images';
import { NavLink } from 'react-router-dom';

function Menu() {
  return (
    <div className="hover-menu">
      <ul>
        <li className="home">
          <NavLink to="/">
            <button>
              <img src={webIcons.home.src} alt={webIcons.home.alt} />
            </button>
          </NavLink>
        </li>
        <li className="que-ofrecemos">
          <NavLink to="/que-ofrecemos">
            <button>
              <img
                src={webIcons.queOfrecemos.src}
                alt={webIcons.queOfrecemos.alt}
              />
            </button>
          </NavLink>
        </li>
        <li className="como">
          <NavLink to="/como">
            <button>
              <img src={webIcons.como.src} alt={webIcons.como.alt} />
            </button>
          </NavLink>
        </li>
        <li className="costos">
          <NavLink to="/costo">
            <button>
              <img src={webIcons.costos.src} alt={webIcons.costos.alt} />
            </button>
          </NavLink>
        </li>
        <li className="donar">
          <NavLink to="/como-donar">
            <button>
              <img src={webIcons.donar.src} alt={webIcons.donar.alt} />
            </button>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default Menu;
