import '../styles/default.scss';
import React from 'react';
import comoDonarTitle from '../assets/images/como-donar-title.svg';
import { siteBranding } from '../constants/images';
import comoDonar_1 from '../assets/images/como-donar-img-1.svg';
import comoDonar_2 from '../assets/images/como-donar-img-2.svg';
import comoDonar_3 from '../assets/images/como-donar-img-3.svg';
import comoDonar_4 from '../assets/images/como-donar-img-4.svg';
import comoDonar_5 from '../assets/images/como-donar-img-5.svg';
import paymentMethods from '../assets/images/payment-methods-01.svg';

function ComoDonar() {
  return (
    <div id="page" className="como-donar">
      <header>
        <h2>
          <img src={comoDonarTitle} alt="¿Cómo donar al..." />
          <span>programa becario?</span>
        </h2>
        <a
          className="mainSite"
          href="https://www.jesusluzdeoportunidades.org.pa/"
          target="_self"
        >
          <img src={siteBranding.logo.src} alt={siteBranding.logo.alt} />
        </a>
      </header>
      <section className="pasos">
        <div className="paso paso-1">
          <img
            src={comoDonar_1}
            alt="Ilustración de una mujer escribiendo una nota"
          />
          <div className="info">
            <p>
              Llenar formulario de <span>donación</span>
            </p>
            <div className="paso-numero">
              <p>1</p>
              <p>decide apoyar</p>
            </div>
          </div>
        </div>
        <div className="paso paso-2">
          <div className="info">
            <div className="paso-numero">
              <p>2</p>
              <p>formulario</p>
            </div>
          </div>
          <img
            src={comoDonar_2}
            alt="Ilustración de hombre anunciando un formulario"
          />
          <div className="footer">
            <p>Enviar formulario al correo</p>
            <p>administracion@jesusluzdeoportunidades.org.pa</p>
          </div>
        </div>
        <div className="paso paso-3">
          <img
            src={paymentMethods}
            alt="Ilustración de un hombre mostrando un tablero de opciones"
          />
          <div className="info">
            <p>Formulario de pago a terceros, yappy o tarjeta</p>
          </div>
          <div className="footer">
            <div className="paso-numero">
              <p>3</p>
              <p>Formas de pago</p>
            </div>
          </div>
        </div>
        <div className="paso paso-4">
          <img
            src={comoDonar_3}
            alt="Ilustración de una mujer escribiendo una nota"
          />
          <div className="info">
            <p>
              Serás ingresado en la <span>base de donantes</span>
            </p>
            <div className="paso-numero">
              <p>4</p>
              <p>donantes</p>
            </div>
          </div>
        </div>
        <div className="paso paso-5">
          <div className="info">
            <div className="paso-numero">
              <p>5</p>
              <p>¿Recurrencia?</p>
            </div>
          </div>
          <img src={comoDonar_4} alt="Ilustración de mujer viendo su celular" />
          <div className="footer">
            <p>
              Confirmar si desea <b>un solo pago o pago recurrente de </b>
              <span>6 o 12 meses.</span>
            </p>
          </div>
        </div>
      </section>
      <section className="closing">
        <h3>Empieza una nueva oportunidad</h3>
        <p>para un joven o adulto en conflicto con la ley.</p>
        <img
          className="closing-illustration"
          src={comoDonar_5}
          alt="Ilustración de hombre siendo alumbrado por una lampara"
        />
        <img
          className="slogan"
          src={siteBranding.sloganColor.src}
          alt={siteBranding.sloganColor.alt}
        />
      </section>
    </div>
  );
}

export default ComoDonar;
