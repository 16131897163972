import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Menu from './components/Menu';
import LandingMobile from './views/LandingMobile';
import MagazineViewer from './views/MagazineViewer';
import QueOfrecemos from './views/QueOfrecemos';
import Como from './views/Como';
import Costo from './views/Costo';
import ComoDonar from './views/ComoDonar';
import WebFont from 'webfontloader';

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Open Sans:400,700', 'Poppins:700', 'PT+Serif:400'],
      },
    });
  }, []);

  return (
    <div className="App">
      <Router>
        {isMobile ? <Menu /> : ''}
        <Routes>
          <Route
            path="/"
            element={isMobile ? <LandingMobile /> : <MagazineViewer />}
          />
          <Route path="/que-ofrecemos" element={<QueOfrecemos />} />
          <Route path="/como" element={<Como />} />
          <Route path="/costo" element={<Costo />} />
          <Route path="/como-donar" element={<ComoDonar />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
